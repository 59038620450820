<template>
  <layout-sub name="contact">
    <b-row
      v-for="(el, index) in list"
      :key="index"
      :class="index > 0 ? 'p-contact mt-5 mt-lg-6 mt-xl-7' : 'p-contact'"
    >
      <b-col cols="12" lg="4">
        <h4 class="mb-3">{{ el.title }}</h4>
        <div v-html="el.content" />
      </b-col>
      <b-col cols="12" lg="8">
        <div :id="`j-map-${index}`" class="p-contact-map"></div>
      </b-col>
    </b-row>
  </layout-sub>
</template>

<script>
import BMapGL from "BMapGL";
import LayoutSub from "@/components/LayoutSub";
import { fatchCurrent } from "@/api";

export default {
  name: "Contact",
  components: { LayoutSub },
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      // 获取当前页面内容
      this.init();
    });
  },
  methods: {
    async init() {
      const { data } = await fatchCurrent(39);
      if (data) {
        this.list = data;

        // 百度地图
        setTimeout(() => {
          data.forEach((el, i) => {
            const map = new BMapGL.Map(`j-map-${i}`); // 创建地图实例
            const myGeo = new BMapGL.Geocoder(); // 创建地址解析器实例
            const addr = el.address; // 获取地址信息

            // 将地址解析结果显示在地图上，并调整地图视野
            myGeo.getPoint(
              addr,
              function (point) {
                if (point) {
                  map.centerAndZoom(point, 16);
                  map.addOverlay(new BMapGL.Marker(point, { title: addr }));
                }
              },
              el.city
            );
          });
        }, 100);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.p-contact {
  &-map {
    height: 500px;
  }

  @include media-breakpoint-up(xl) {
    h4 {
      font-size: $h3-font-size;
    }

    ul {
      font-size: $font-size-lg;
    }
  }
}
</style>
